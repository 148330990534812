.personalizacao-contato .endereco textarea{
    height: 146px;
}

.personalizacao-contato .emails hr{
    margin-top: 26px;
}

.personalizacao-contato .emails .form-group:last-child{
    margin-top: 18px;
}

.personalizacao-contato .telefones .custom-switch{
    margin-bottom: 0.4rem;
}

#termo{
    min-height: 350px;
}