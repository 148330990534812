.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    height: 100%;
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

/* Arrows */

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
    font-size: 24px;
    line-height: 20px;
}

.left-arrow {
    left: 24px;    
}

.right-arrow {
    right: 24px;
    padding-right: 3px;
}

/* Hide arrows on touchscreen devices */
@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}

/* Multiple Carousel */
.carousel-content.show-2 {
    width: 50%;
}

.carousel-content.show-3 {
    width: calc(100% / 3);
}

.carousel-content.show-4 {
    width: calc(100% / 4);
}

/* Centralizing */
.carousel-content.length-1 {
    margin-left: calc(50% - 150px);
}

.carousel-content.length-2 {
    margin-left: 25%;
}

.carousel-content.length-3 {
    margin-left: 13%;
}
