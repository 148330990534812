.cod{
    justify-content:space-between;
    margin-top: 20px;
}
.cod div{
    border-Bottom: 1px solid #e0e0e0;
}
.cod b{
    margin-right: 10px;
}
.areaEsq{
    display: flex;
    margin-top: 10px;
    justify-content:space-between;
    border-Bottom: 1px solid #e0e0e0;
}
.areaEsq b{
    margin-right: 10px;
}
.vidro{
    margin-top: 10px;
    border-Bottom: 1px solid #e0e0e0;
}
.vidro b{
    margin-right: 10px;
}
.local{
    margin-top: 10px;
    border-bottom: 1px solid #e0e0e0;
}
.local b{
    margin-right: 10px;
}
.linhaValor{
    align-content:center;
    margin-top: 10px;
}
#value{
    display: flex;
}
.linha{
    padding: 0;
}
.linha b{
    margin-right: 5px ;
}
.line b{
    color: rgb(69, 129, 207);
}
.larg{
    padding-right: 15px;
    padding-left: 15px;
}
.cardValor{
    display: flex;
    text-align: center;
    justify-content:center;
}
.valorUn{
    margin-right: 5px;

}
.valorUn b{
    color: rgb(69, 129, 207);
}
.valorTotal b{
    color: #01d101;
} 
@media(max-width:576px){
    #glass{
        border-bottom: 1px solid #e0e0e0;
    }
    #miter{
        margin-top: 10px;
    }
    #value{
        text-align: center;
    }
}