.icon-budget i {
    font-size: 1.5rem;
    color: var(--secondary);
}

/* h5{
    color: rgb(69, 129, 207) !important;
} */

table tr td {
    vertical-align: middle !important;
}

.subtitle {
    line-height: 2.35rem;
}
div > span {
    font-size: 14px;
}
/* Inicio - Cards da Grid das propostas*/
.container{
    padding-top: 5px;
}
.card-dados{
    padding: 0;
}
.nomeCod{
    align-items: center;
    
}
#cod{
    margin-top: 2px;
}

.dado{
    margin: auto;
    align-items: center;
}
.dado label{
    margin-bottom: 0;
}
.row{
    justify-content:space-between;
}
.row h5{
    color: rgb(69, 129, 207);
    margin-bottom: 0;
}
#valor{
    color:#01d101;
}
.statusBtt{
    align-items: center;
    display: flex;
    padding: 0;
}
#button{
    justify-content: end!important;
}
.versao label{
    margin: 0;
}

@media(max-width:578px){
    .nomeCod{
        border-bottom: 1px solid #e0e0e0;
        display:flex;
        padding:  0;
        justify-content: end;
        margin-top: 10px;
        align-items: center;
    }
    .dado{
        margin: 0px 0 15px -10px;
    }
    .orca{
        margin-top: 20px;
    }
    .statusBtt{
        border-top: 1px solid #e0e0e0;
      
        padding-top: 15px;
    }
    .link{
        height: 100%;
    }
    .col-md{
        padding-top: 10px;
    }
    .col-md-auto{
        padding-top: 10px;
    }
    .versao{
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }
    .versao label{
        margin-right: 10px;
    }
}   

@media (min-width:579px) and (max-width: 768px) {
    .nomeCod{
        border-bottom: 1px solid #e0e0e0;
        display:flex;
        justify-content:start;
        margin-left: -10px;
        /* flex-direction:row;
        padding:  0;
        margin-top: 10px;
        align-items: center; */
    }
    #cod{
        margin-left: -100px;
    }
    .dado{
        margin: 15px 0 15px -10px;
    }
    .statusBtt{
        border-top: 1px solid #e0e0e0;
        padding-top: 15px;
    }
    .link{
        height: 100%;
    }
    thead {
        display: none;
    }

    tr {   
        display: flex;
        flex-direction: column;    
    }

    tr:not(:first-child) {
        margin: 3rem 0;
    }

    tr:after {
        content: "";
        border-top: 1px solid rgba(101,109,119,.16);
        transform: translateY(1.48rem);
    }

    td[data-label]:before {
        font-size: .75rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .04em;
        line-height: 1.6;
        color: #656d77;
        content: attr(data-label);
        display: block;
    }

    td {
        display: block;
        padding: .5rem .5rem!important;
        border: none !important;
        color: #232e3c!important;
    }
    .versao{
        display: flex;
        flex-direction: row;
    }
}