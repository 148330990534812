/*Sucesso*/
.Toastify__toast--success { 
    color: #155724 !important;
    background-color: #d4edda !important;
    border: #c3e6cb solid 1px !important;
}
.Toastify__toast--success .Toastify__close-button {
    color: #155724 !important;
}

.Toastify__toast--success .Toastify__progress-bar {
    background-color: #155724 !important;
}

/*Erro*/
.Toastify__toast--error {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border: #f5c6cb solid 1px !important;
}
.Toastify__toast--error .Toastify__close-button {
    color: #721c24 !important;
}
.Toastify__toast--error .Toastify__progress-bar {
    background-color: #721c24 !important;
}

/*Warning*/
.Toastify__toast--warning {
    color: #705c09 !important;
    background-color: #e9ddab !important;
    border: #e9d483 solid 1px !important;
}
.Toastify__toast--warning .Toastify__close-button {
    color: #705c09 !important;
}
.Toastify__toast--warning .Toastify__progress-bar {
    background-color: #d1aa0e !important;
}