#divSaibaMais {
    position: fixed!important;
    bottom: 80px;
    right: 150px;
}
.saibaMais {
    position: fixed;
    border-radius: 30px;
    font-size: 20px;
}

.info-card i {
    font-size: 22px;
    padding: 8px;
    border-radius: 50%;
    color: #467fcf;
    text-align: center;
}

.info-card h4 {
    margin: 26px 0 22px;
    text-align: center;
}

.info-card p {
    margin-bottom: 0;
}

.carousel-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-cover-fornecedor {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid rgba(0, 40, 100, 0.12);
    padding: 1rem;
    border-radius: 5px;
}
#img{
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 400px;
    margin-top: -5px;
}
.texto{
    height: 300px;
}
#bv{
    align-items: center;
}
h2{
    padding-top: 10px;
}
.numPO{
    text-align: start;
}
.bg-responsive {
    background-size: contain !important;
    background-position: center center !important;
}

@media(max-width:768px){
    #bv{
        text-align:center;
        width: 100%;
        height: 100%;
        margin: 0;
    }
    #bv p{
        text-align: center;
        margin: 0;
        padding: auto;
        width: auto;
        
    }
    #img{
        width: 90px;
        height: 350px;
        border-bottom-left-radius: 15px;
        border-top-right-radius: 0;
    }
    .texto{
        height: auto;
        padding-bottom: 20px;
    }
    .numPO{
        text-align: center;
    }
}