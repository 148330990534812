.dimmer {
    position: relative;
 }
  
.dimmer .loader {
  display: none;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
  
.dimmer.active .loader {
    display: block;
}
  
.dimmer.active .dimmer-content {
  opacity: .03;
  pointer-events: none;
}