.img-cover {
    width: 100%;
    height: 220px;
    object-fit: cover;
}

.dimmer .progresso {
    display: none;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .dimmer.active .progresso {
    display: block;
  }