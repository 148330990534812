.personalizacao-empresa .img-cover {
    width: 100%;
    height: 310px;
    object-fit: cover;
    border-radius: 2px;
}

.personalizacao-empresa textarea {
    height: 150px;
}
.bg-responsive {
    background-size: contain !important;
    background-position: center center !important;
    min-height: 400px;
}