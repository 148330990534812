#profile::after {
    display: none !important;
}

.nav-tabs .nav-item svg {
    margin-right: .25rem;
    line-height: 1;
    width: 0.875rem;
    vertical-align: baseline;
    display: inline-block;
}
#menu-inicio:focus{
    outline:0 !important;
    border: 0 !important;
    box-shadow: none;
}
/* #navbarIncio {
    background-color: rgb(69, 129, 207);
    position: fixed;
    top: 5px;
    width: 100%;
    display: block;
    transition: top 0.3s;
  }
  
  #navbarIncio a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 15px;
    text-decoration: none;
    font-size: 17px;
  }
  
  #navbarIncio a:hover {
    background-color: #ddd;
    color: black;
  }
     */