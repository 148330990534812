.headerDetalhe{
    margin-right: 15px;
    margin-left: 15px;
}
.headerDetalheRow{
    margin-right: 15px;
    margin-left: 15px;
    align-items: center;
}
.containerDetalhe{
padding-left: 25px;
padding-right: 25px;
}

.btnDetalhe{
    justify-content: space-between!important;
    align-items:center;
    display: block!important;
}

.btnSendPO{
    justify-content: left;
    margin-right: 5px;
    margin-left: 5px;
}
.btnSendPORow{
    justify-content: end;
}

#recuse{
    padding-right: 0;
    padding-block: 0;
}

.btnsCliente{
    margin-top: 15px;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
}

.colFechar{
    padding: 5px;
}

.btnRecusar{
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 1px;
    padding-left: 6px;
}

.rowArea{
    margin-top: 6px;
    margin-left: 5px;
    margin-right: 5px;
    align-items: center;
    justify-content: space-between;
}

.btnDadosAdd{
    display: block;
    padding: 0;
    margin-top: 10px;
    margin-left: 0;
}


.btnEsquadria{
    margin-top: 25px;
}

.valorTotal{
    text-align: end;
    justify-content: end;
    font-size: 16px;
    margin-right: 20px;
}

.tab-versao {
 margin-top: -6px !important;
}
.icon-budget i {
    font-size: 2rem;
    color: var(--secondary);
}
.versaoCorrente {
    font-weight: bold !important;
    text-align: center;
    font-size: 12px;
}
.versao{
    text-align: center;
    font-size: 12px;
}

.detalheRow{
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
}

.detalhe{
    align-self:center;;
}
.modal-content textarea.form-control {
    min-height: 260px;
}
#title h5{
    color:rgb(69,129,207); 
    text-Align:left;
    margin-bottom: 0;
}
#items{
    display: flex;
    flex-direction:column;
    align-self: center;
    margin: auto;
    margin-bottom: 13px;
}
#items label{
    margin-bottom: 0;
}
.status{
    margin: auto;
    align-self: center;
}
.valor{
    color:#01d101;
   
}
.valorTotal{
    display: flex;
    
}
#plant{
    align-self:center;
}
.card{
    border-radius: 10px;
    padding: 0;
}
#refuse{
    margin-left: 15px;
}
.ajusteCol{
    margin-top: 10px;
}
.colEnviar{
    padding: 0px;
}

.btnDadosCol{
    padding: 0;
}
 
.btnTermo{
    padding-left: 0;
    padding-right: 0;
    margin-top: 10px;
}

.btnPlanta{
    margin: 0;
    padding: 0;
    /* padding-right: 5px!important; */
    margin-top: 10px;
}

.btnplantabaixa{
    padding: 0;
}

.btnEsquadria{
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

@media screen and (min-width:575px) and (max-width:992px){
    .plantabaixaSm{
        padding-left: 0.75rem!important;
    }
    .btnSendPO{
        margin-right: 5px;
        margin-left: 0px;
    }
    .ajusteCol{
        margin-top: 0px;
    }

    /* .colFechar{
        padding: 0px;
    } */

    .btnRecusar{
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 5px;
        padding-right: 0px;
    }

    .rowArea{
        margin-left: 0px!important;
        margin-right: 0px!important;
    }
    .btnDadosAdd{
        display: flex;
    }

    .btnTermo{
        padding-left: 5px;
        margin-top: 0;
    }
    
    .btnPlanta{
        padding-right: 10px;
    }

    .btnEsquadria{
        padding-right: 10px;
    }
}

@media(max-width:576px){
    #icone i{
        display: none;
        margin-top: 0;
    }
    #title{
        display:flex;
        border-bottom: 1px solid #e0e0e0;
        margin-top: 10px;
        width: auto;
    }
    #title div{
        text-align: right;
        padding-right: 20px;
    }
    #items{
        margin: 0;
        margin-top: 5px;
        margin-bottom: 8px;
    }
    #container-item{
        border-bottom: 1px solid #e0e0e0;
        padding: 20px 10px;
    }
    #title h5{
        padding-left: 0;
    }
    .text-muted{
        text-align: right;
    }
    #recuse{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    #plant{
        padding-top: 13px;
        border-top: 1px solid #e0e0e0;
    }
    .btnplantabaixa{
        padding-left: 0!important;
    }
    .colFechar{
        padding: 0px;
    }
    .btnRecusar{
        padding-left: 0px;
        padding-right: 0px;
    } 

    .btnDadosCol{
        padding-right: 0;
        padding-left: 0;
    }
} 

@media(min-width:992px) {
    .btnDetalhe{
        display: flex!important;
    }
    .rowArea{
        margin-left: -4px!important;
        margin-right: 0px!important;
    }
    .btnDadosAdd{
        display: flex;
    }        
    .btnTermo{
        padding-left: 5px;
        margin-top: 0;
    }
    
    .btnPlanta{
        padding-right: 0px;
    }
}

@media(min-width:768px) {
    .btnplantabaixa{
        margin-top: 0;
    }
    
    .btnPlanta{
        padding-right: 5px!important;
        padding-left: 5px!important;        
    }
}