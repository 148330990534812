.area-3D {
  background-image: url(../../assets/img/bg.jpg);
  background-color: #f1f1f2;
  /* height: 100vh; */
}

.container-colors {
  position: absolute;
  left: 50%;
  bottom: 2%;
  width: 90%;
  max-width: 266px;
  transform: translate(-50%, 0);
  z-index: 999;
}

.colors {
  position: relative;
  background: rgb(187 192 195 / 39%);
  border-radius: 6px;
  padding: 4% 0;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.colors .dropup {
  display: flex;
  flex-direction: row;
  
}

.colors a.dropdown-toggle:hover {
  text-decoration: none;
  cursor: pointer;
}

.colors .dropup .dropdown-toggle::after {
  display: none !important;
}


.container-colors .dropdown-menu {
  background: none;
  border: none;
  min-width: max-content;
  box-shadow: none;
}

.container-colors .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
}

.container-colors .dropdown-menu.show {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
}

.container-colors .dropdown-menu .cor {
  float: left;
  width: 66px;
  height: 66px;
  list-style: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cor.branco {
  background-color: #fff;
}

.cor.cinza {
  background-color: #c5c5c5;
}

.cor.metalico {
  background-image: -webkit-repeating-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0) 0%,
      hsla(0, 0%, 100%, 0) 6%,
      hsla(0, 0%, 100%, 0.1) 7.5%
    ),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 0%, 0) 0%, hsla(
            0,
            0%,
            0%,
            0
          )
          4%, hsla(0, 0%, 0%, 0.03) 4.5%),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(
            0,
            0%,
            100%,
            0
          )
          1.2%, hsla(0, 0%, 100%, 0.15) 2.2%),
    linear-gradient(
      180deg,
      hsl(0, 0%, 78%) 0%,
      hsl(0, 0%, 90%) 47%,
      hsl(0, 0%, 78%) 53%,
      hsl(0, 0%, 70%) 100%
    );
}

.cor.dourado {
  background-image: -webkit-repeating-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0) 0%,
      hsla(0, 0%, 100%, 0) 6%,
      hsla(0, 0%, 100%, 0.1) 7.5%
    ),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 0%, 0) 0%, hsla(
            0,
            0%,
            0%,
            0
          )
          4%, hsla(0, 0%, 0%, 0.03) 4.5%),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(
            0,
            0%,
            100%,
            0
          )
          1.2%, hsla(0, 0%, 100%, 0.15) 2.2%),
    linear-gradient(
      180deg,
      hsl(43, 74%, 65%) 0%,
      hsl(44, 74%, 85%) 47%,
      hsl(43, 74%, 50%) 53%,
      hsl(43, 74%, 49%) 100%
    );
}

.cor.verde {
  background-color: #76d7c4;
}

.cor.azul {
  background-color: #85c1e9;
}

.cor.preto {
  background-color: #000;
}

:host {
  display: block;
  position: relative;
  contain: strict;
  width: 300px;
  height: 150px;
}

:host([data-js-focus-visible]:focus:not(.focus-visible)),
:host([data-js-focus-visible]) :focus:not(.focus-visible) {
  outline: none;
}


@media screen and (max-width: 767px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }

  .colors {
    z-index: 999;
  }

  .dropdown-menu .cor {
    width: 50px;
    height: 50px;
  }

  .dropdown-toggle img {
    width: 50px;
    height: 50px;
  }
}
