.selecionar-plantas .img-cover {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.selecionar-plantas .imagecheck-figure {
  border: 0;
  z-index: 0;
}
.selecionar-plantas .imagecheck-image {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selecionar-plantas .imagecheck-input:focus ~ .imagecheck-figure {
  border-color: rgba(0, 40, 100, 0.05);
  box-shadow: 0 0 0 2px rgba(0, 40, 100, 0.05);
}

.selecionar-plantas .imagecheck-input:checked ~ .imagecheck-figure {
  border-color: rgba(0, 40, 100, 0.24);
}

.selecionar-plantas .imagecheck-figure:before {
  content: '';
  position: absolute;    
  top: calc(100px - 0.9rem);
  left: initial;
  right: 0.5rem;
  display: block;
  line-height: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff center center/50% 50%;
  color: #fff;
  z-index: 1;
  border: #467fcf 2px solid;
  box-shadow: 0 0 15px 2px rgba(77, 77, 77, 0.5);
  border-radius: 50%;
  transition: .3s;
  opacity: 0.8;
}

.selecionar-plantas .imagecheck-input:checked ~ .imagecheck-figure:before {
  background: #467fcf url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/55%;
  border: #fff 2px solid;
  color: #fff;
  z-index: 1;
  opacity: 1;
}  
   

.selecionar-plantas .imagecheck-input:focus ~ .imagecheck-figure .imagecheck-image,
.selecionar-plantas .imagecheck-input:checked ~ .imagecheck-figure .imagecheck-image {
  opacity: 1;
}

.selecionar-plantas .imagecheck-input:not(:checked) ~ .imagecheck-figure .imagecheck-image {
  opacity: .64;
}
.selecionar-plantas .imagecheck-image:hover {
  opacity: 1 !important;
}

#menu {
  display: none;
  position: absolute;
  width: 44px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
}

#menu button {
  width: 100%;
  background-color: white;
  margin: 0;
  padding: 6px;
}

#menu button:hover {
  background-color: lightgray;
}
