.password-tips {
    font-size: 0.87rem;    
}

.password-tips p {
    margin-bottom: 0.3em;
}

.password-tips ul {
    padding-left: 17px;
    margin-bottom: 0;
}

.password-tips li {
    line-height: 1.2rem;
}