/* Tipologia */

.item-removido .card {
    background-color: #f8f9fa;
}
.dimmer-content.item-removido {
    opacity: 0.5 !important;
}

/* Imagens */

.vincular-imagens .img-cover {
    width: 100%;
    height: 132px;
    object-fit: cover;
    display: block;
}
#videoCarousel{
    border-radius: 30px;
    border: 3px solid #6292d5;
    width: 60px;
    height: 60px;
    margin-top: 105px;
    font-size: 25px;
}
#logo{
    margin-top: 110px;
}

.carousel-indicators{
    display: none;
}

.vincular-imagens .imagecheck-figure {
    border: 0;
    z-index: 0;
}
.vincular-imagens .imagecheck-image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.vincular-imagens .imagecheck-input:focus ~ .imagecheck-figure {
    border-color: rgba(0, 40, 100, 0.05);
    box-shadow: 0 0 0 2px rgba(0, 40, 100, 0.05);
}
  
.vincular-imagens .imagecheck-input:checked ~ .imagecheck-figure {
    border-color: rgba(0, 40, 100, 0.24);
}
  
.vincular-imagens .imagecheck-figure:before {
    content: '';
    position: absolute;    
    top: calc(132px - 1.25rem);
    left: initial;
    right: 0.5rem;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff center center/50% 50%;
    color: #fff;
    z-index: 1;
    border: #467fcf 2px solid;
    box-shadow: 0 0 15px 2px rgba(77, 77, 77, 0.5);
    border-radius: 50%;
    transition: .3s;
    opacity: 0.8;
}
  
.vincular-imagens .imagecheck-input:checked ~ .imagecheck-figure:before {
    background: #467fcf url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
    border: #fff 2px solid;
    color: #fff;
    z-index: 1;
    opacity: 1;
}  
     

.vincular-imagens .imagecheck-input:focus ~ .imagecheck-figure .imagecheck-image,
.vincular-imagens .imagecheck-input:checked ~ .imagecheck-figure .imagecheck-image {
    opacity: 1;
}
  
.vincular-imagens .imagecheck-input:not(:checked) ~ .imagecheck-figure .imagecheck-image {
    opacity: .64;
}
.vincular-imagens .imagecheck-image:hover {
    opacity: 1 !important;
}


/* Videos */

.vincular-videos .player-wrapper {
   padding-top: 132px;
   background-color: #000;
}

.vincular-videos .imagecheck-figure {    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.vincular-videos .imagecheck-figure:before {
    content: '';
    position: absolute;    
    top: calc(132px - 1.25rem);
    left: initial;
    right: 0.5rem;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    pointer-events: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff center center/50% 50%;
    color: #fff;
    z-index: 999;
    border: #467fcf 2px solid;
    box-shadow: 0 0 15px 2px rgba(77, 77, 77, 0.5);
    border-radius: 50%;
    transition: .3s;
    opacity: 0.8;
  }
  
.vincular-videos .imagecheck-input:checked ~ .imagecheck-figure:before {
    background: #467fcf url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
    border: #fff 2px solid;
    color: #fff;
    z-index: 999;
    opacity: 1;
}  

.vincular-videos .imagecheck-input:focus ~ .imagecheck-figure .imagecheck-image,
.vincular-videos .imagecheck-input:checked ~ .imagecheck-figure .imagecheck-image {
    opacity: 1;
}
  
.vincular-videos .imagecheck-input:not(:checked) ~ .imagecheck-figure .imagecheck-image {
    opacity: .64;
}
.vincular-videos .imagecheck-image:hover {
    opacity: 1 !important;
}
 
.vincular-videos .imagecheck-input:focus ~ .imagecheck-figure {
    border-color: rgba(0, 40, 100, 0.05);
    box-shadow: 0 0 0 2px rgba(0, 40, 100, 0.05);
}

.vincular-videos .imagecheck-figure {
    border: 0;
    z-index: 0;
}  
  
.vincular-videos .imagecheck-input:checked ~ .imagecheck-figure {
    border-color: rgba(0, 40, 100, 0.24);
}
#value{
    justify-content: space-between;
    margin-top: 8px;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
    align-items: center;
}
.tipo{
    padding: 1px;
    margin-top: 5px;
    background: #dbe9f7;
}
.tipo b{
    margin-right: 10px;
    color: rgb(69, 129, 207);
 }
.btnEdicao{
    display:flex;
    justify-content:space-between;
}
.btnEdicao h4{
    text-transform: uppercase;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}
.btnEdicao div{
    display: flex;
    padding: 0;
    margin: 0;
    text-align: center;
}
.btnEdicao Button{
    border-radius:7px;
}
@media(max-width:576px){
    #glass{
        border-bottom: 1px solid #e0e0e0;
    }
    .btnEdicao Button{
       height: 40px;
       align-self: center;
    }
    #miter{
        margin-top: 0px;
    }
    #line{
        padding: 0;
    }
    #value{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
      
} 
  