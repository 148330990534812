.toggle-lateral {
    min-width: 0px;
    padding: 0 4px;
}

.toggle-lateral i{
    letter-spacing: 0;
    font-size: 1.2rem;
    vertical-align: -3px;
}

.width:not(.show) {
    transition: all 0.25s;
    display: block !important;
    width: 0;
    opacity: 0;
}
.width.show {   
    transition: all 0.25s;
    width: 100%;
    display: block;
    opacity: 1;
}

.grow-left {
    direction: rtl;    
}
.grow-left > * {
    direction: ltr;
}

.coluna-lateral .collapsing {
    position: initial !important;
    height: initial !important;
    overflow: initial !important;
    transition: initial !important;
}
.linhas{
    border-bottom: 1px solid #e0e0e0;
    margin-top: 10px;
}
