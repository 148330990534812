.opcoes-cover {
    width: 100%;
    height: 190px;
    object-fit: cover;
}

@media (min-width: 992px) {
    .modal-opcoes {
        width: 85%;
        max-width: 1200px;
    }
}

 